<template>
  <div class="p-grid mail-box">
    <div v-if="profiles.length !== 0 || loading" class="p-col-12">
      <view-switcher
        :layout="layout"
        :loading="loading"
        :length="profiles.length"
        @toggleView="toggleView"
      />
      <div>
        <list-view
          v-if="layout === 'list'"
          :profiles="profiles"
          :loading="loading"
          :profile-number="profileNumber"
          :load-profiles="loadProfiles"
          :block-page="true"
          @updateMails="removeFromMails"
          @blockUser="blockUser"
        >
          <!-- deleted -->
          <template #deletedProfile="{}">
            <div class="list-item">
              <div class="item-itself">
                <p class="for-text-list for-text-list-del p-text-center p-p-0">
                  This profile is deleted
                </p>
              </div>
            </div>
          </template>
          <template #activeProfile="{ profile }">
            <div class="list-item">
              <div class="item-itself">
                <Button
                  class="p-button p-text-center"
                  label="Submit"
                  @click="unblockProfile(profile.data.attributes.id)"
                >
                  Unblock
                </Button>
              </div>
            </div>
          </template>
        </list-view>
        <grid-view
          v-else
          :profiles="profiles"
          :loading="loading"
          :profile-number="profileNumber"
          :load-profiles="loadProfiles"
          :block-page="true"
          @updateMails="removeFromMails"
          @blockUser="blockUser"
        >
          <template #default="{ profile }">
            <div class="footer-content p-p-0">
              <div class="p-d-flex p-jc-between footer-content">
                <Button
                  v-if="profile.data.attributes.is_active"
                  class="p-button-sm p-text-center"
                  label="Unblock "
                  @click="unblockProfile(profile.data.attributes.id)"
                />
                <p
                  v-else
                  class="for-text-del p-text-center p-d-flex p-ai-center pad"
                >
                  {{
                    profile.data.attributes.is_active
                      ? 'Blocked'
                      : 'This profile is deleted'
                  }}
                </p>
              </div>
            </div>
          </template>
        </grid-view>
      </div>
    </div>
    <div v-else class="p-col-12">
      <empty-mails
        :empty-text="'Currently you don\'t have any one blocked.'"
        :img="'/blocked.png'"
      />
    </div>
  </div>
</template>

<script>
import GridView from '@/components/views/GridView.vue';
import ListView from '@/components/views/ListView.vue';
import '@/assets/ListAndGridView.scss';
import EmptyMails from '@/components/EmptyMails.vue';
import { mailboxMixin } from '@/mixins/mailboxMixin';
import { mapGetters } from 'vuex';
import ViewSwitcher from '@/utilities/ViewSwitcher.vue';

export default {
  components: {
    GridView,
    ListView,
    EmptyMails,
    ViewSwitcher,
  },
  mixins: [mailboxMixin],
  computed: {
    ...mapGetters(['authUser']),
    reqUrl() {
      return `/users/${this.authUser.slug}/blocked_profiles`;
    },
  },
  methods: {
    unblockProfile(id) {
      let that = this;
      this.$http
        .patch(`users/${this.authUser.slug}/unblock_profile`, {
          user_id: id,
        })
        .then(() => {
          that.removeFromMails(id);
          that.$moshaToast('Unblocked the profile', {
            type: 'success',
            showIcon: true,
            timeout: 2000,
          });
        })
        .catch(() => {
          that.$moshaToast('Something went wrong', {
            type: 'danger',
            showIcon: true,
            timeout: 2000,
          });
        });
    },
  },
};
</script>
